import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Styles from '../lib/map-styles';
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Marker = () => <div><FontAwesomeIcon icon={faMapMarkerAlt} size="2x" /></div>;

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 54.942430,
            lng: -1.715300
        },
        zoom: 11,
        options :{
            zoomControl : false,
            disableDefaultUI: true,
            draggable: false,
            styles: Styles
        }
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div className={"c-simple-map"} style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDP-jrLD4h11ZcLfwVxHBt3FxvYKKPnaKg' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    maxZoom={this.props.zoom}
                    minZoom={this.props.zoom}
                    options={this.props.options}>
                    <Marker
                        lat={54.942430}
                        lng={-1.715300}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;
