import TextGrid from "./TextGrid";
import React, {useState} from "react";
import PropTypes from 'prop-types'
import Switch from 'react-ios-switch';
import Button from "./Button";
import ContactLinks from "./ContactLinks";
import Avatar from "./Avatar";
import avatarImg from './../img/avatar.jpg';
import '../styles/components/home-about.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Template = ({
                      info,
                      moreInfo,
                  }) => (

    <section id={"home-about"}
             className={"c-home-about l-flex l-flex--column l-flex--space-between l-component"} >
        <div className={"l-container l-container--indented l-flex l-flex--stretch l-flex--column l-flex--space-between"}>
            {info}

            {moreInfo}

            <div className={"c-home-about--links l-flex l-flex--column l-flex--center-middle"}>
                <ContactLinks></ContactLinks>
            </div>
        </div>
    </section>
);

const HomeAbout = ({aboutItems}) => {
    const [tooTechnical, settooTechnical] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const activeItems = tooTechnical ? aboutItems[1] : aboutItems[0];

    const handleContextSwitch = (val) => {
        settooTechnical(val);
    }

    const toggleMoreInfo = (e) => {
        setShowMoreInfo(!showMoreInfo);
    }

    const getContent = (tooTechnical) => {
        if(tooTechnical) {
            return ([
                <p key={1}>
                    You can found out more or get in touch below.
                </p>
            ]);
        }

        return ([
            <p key={1}>
                You can found out more or get in touch below.
            </p>
        ]);
    }

    const getInfo = () => (
        <div className={"l-flex l-flex--stretch animate__animated animate__fadeInLeft"}>
            <div className={"l-flex l-flex--stretch l-flex--column l-flex--middle c-home-about--main"}>
                <p className={"copy-header copy-header--xl"}>
                    About
                </p>

                <Avatar
                    image={avatarImg}
                    width={"120px"}
                    height={"120px"}></Avatar>

                <p className={"c-home-about--copy copy-subheader"}>
                    I'm an experienced fullstack developer with a pragmatic outlook & strong problem solving skills.  Given the choice, I favour backend work but have the skills and experience to work on either discipline.
                </p>

                <p className={"c-home-about--copy copy-subheader"}>
                    My favoured technologies are Laravel & Vue, but I have experience with a wide range of tools including ZF1/2, Yii, Craft, Wordpress, React, Eleventy & more
                </p>

                <div className={"c-home-about--buttons"}>
                    <Button click={(e) => toggleMoreInfo(e)}>
                        <span>More</span>
                    </Button>

                    <Button link={'/contact'}>
                        <span>Contact</span>
                    </Button>
                </div>
            </div>
        </div>
    );

    const getMoreInfo = (content) => (
        <div className={"c-home-about--more animate__animated animate__fadeInRight"}>
            <div className={"l-component l-flex l-flex--wrap"}>
                <div className={"l-flex l-flex--stretch l-flex--start l-flex--space-between"}>
                    <Button type={"text"} click={(e) => toggleMoreInfo(e)}>
                        <FontAwesomeIcon icon={faArrowLeft}  size="s" />
                        <span>Back</span>
                    </Button>

                    <div className={"c-home-about--switch"}>
                        <p className={"copy-subheader copy-subheader--small copy-subheader-secondary"}>
                            Too technical
                        </p>

                        <Switch checked={tooTechnical}
                                onChange={handleContextSwitch}
                                onColor="#4ac15e"
                                offColor="#252525"/>
                    </div>
                </div>
            </div>

            <div className={"l-component c-home-about--more--intro-wrap"}>
                <div className={"c-home-about--more--intro copy-large"}>
                    <div className={"l-flex"}>
                        <Avatar image={avatarImg} width={"70px"} height={"70px"}></Avatar>

                        <div>
                            <h3 className={"copy-header copy-header--s"}>About me</h3>

                            {content}
                        </div>
                    </div>
                </div>
            </div>

            <TextGrid items={activeItems} showContact={true}></TextGrid>
        </div>
    );

    const moreInfo = showMoreInfo ? getMoreInfo(getContent(tooTechnical)) : null;
    const info = showMoreInfo ? null : getInfo();

    return (
        <Template info={info}
                  moreInfo={moreInfo}
                  toggleMoreInfo={toggleMoreInfo}></Template>
    );
}

HomeAbout.propTypes = {
    aboutItems : PropTypes.array
}

export default HomeAbout;
