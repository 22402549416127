import React from 'react'
import PropTypes from "prop-types";
import Button from "./Button";
import aboutVideo from '../../static/video/bg-optimised.mp4';
import aboutVideoMObile from '../../static/video/bg-mobile.mp4';
import aboutPoster from '../../static/img/video-frame-1-min.jpg';
import {useMediaQuery} from "../hooks/UseMediaQuery";
import {scrollToEle} from "../lib/scroll";
import '../styles/components/home-hero.scss'

const Template = ({title, getSource, subheading, scrollTo}) => (
    <div className="c-home-hero-wrap l-component">
        <div className="c-home-hero l-flex l-flex--center-middle">
            <div>
                <h1 className="copy-header copy-header--huge animate__animated animate__fadeInLeft animate__delay-100ms">
                    {title}
                </h1>

                <h3 className="copy-subheader animate__animated animate__fadeInRight animate__delay-1200ms">
                    {subheading}
                </h3>

                <div className="c-home-hero__buttons animate__animated animate__fadeInUp animate__delay-1200ms">
                    <Button click={(e) => scrollTo(e,'#home-projects')}>
                        <span>View work</span>
                    </Button>

                    <Button click={(e) => scrollTo(e, '#home-about')}>
                        <span>About</span>
                    </Button>
                </div>
            </div>
        </div>

        <video loop
               muted
               autoPlay
               className={"c-home-hero__video"}
               poster={aboutPoster}>
            <source src={getSource()} type="video/mp4" />
        </video>
    </div>
);

const HomeHero = ({title, subheading}) => {
    const isMobile = useMediaQuery('(max-width: 768px)')

    const scrollToHandler = (e, selector) => {
        e.preventDefault();
        scrollToEle(selector);
    }

    const getSource = () => {
        if(isMobile) {
            return aboutVideoMObile;
        }

        return aboutVideo;
    };

    return (<Template title={title}
                      getSource={getSource}
                      subheading={subheading}
                      scrollTo={scrollToHandler}></Template>);
}

HomeHero.propTypes = {
    title: PropTypes.string,
    subheading: PropTypes.string
}

export default HomeHero
