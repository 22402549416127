import React, {useRef, useEffect, Children} from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import '../../styles/all.scss'
import PropTypes from "prop-types";
import {useGlobalState} from "../../store/app";
import Footer from '../Footer';

const TemplateWrapper = ({ pageName, title, description, children }) => {
    const [navOpen, setNavOpen] = useGlobalState('navOpen');
    const navBar = React.createRef();
    const ref = useRef(null);

    const handleNavBarOpen = (open) => {
        setNavOpen(open);
    };

    return (
        <div className={`p-scroller-layout p-${pageName}`} ref={ref}>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
                <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
                <meta name="description" content={description}/>
                <title>{title}</title>
            </Helmet>
            <Navbar onToggle={handleNavBarOpen}
                    visible={false}
                    ref={navBar} />
            {children}

            <Footer showCta={true}/>
        </div>
    )
}

TemplateWrapper.propTypes = {
    pageName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default TemplateWrapper
