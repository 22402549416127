import React from 'react'
import PropTypes from "prop-types";
import ContactForm from './ContactForm';
import SimpleMap from "./SimpleMap";
import ContactLinks from "./ContactLinks";
import '../styles/components/home-contact.scss'

const Template = () => (
    <div id={"home-contact"}
         className="c-home-contact">
        <div className={"c-home-contact--container l-flex l-flex--space-between"}>
            <div className={"c-home-contact--map"}>
                <SimpleMap></SimpleMap>
            </div>

            <div className={"c-home-contact--right"}>
                <div className={"c-home-contact--top"}>
                    <h3 className={"copy-header"}>Contact</h3>

                    <p>You can reach me using the form or details below</p>

                    <ContactForm></ContactForm>
                </div>

                <div className={"c-home-contact--details l-flex"}>
                    <div className={"c-home-contact--hours"}>
                        <p className={"copy-header copy-header--s"}>
                            Working hours
                        </p>

                        <p>
                            I work Monday-Friday, and over the weekend when necessary
                        </p>

                        <ul>
                            <li>Mon-Fri 8:30am - 5:30pm</li>
                            <li>Saturday 9am - 12pm</li>
                            <li>Saturday 10am - 12pm</li>
                        </ul>
                    </div>

                    <div className={"c-home-contact--list"}>
                        <p className={"copy-header copy-header--s"}>
                            Contact details
                        </p>

                        <ContactLinks labels={true}></ContactLinks>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const HomeHero = () => {
    return (<Template />);
}

HomeHero.propTypes = {
    title: PropTypes.string,
    subheading: PropTypes.string
}

export default HomeHero
