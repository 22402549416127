import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ScrollerLayout from '../components/layout/ScrollerLayout'
import HomeHero from "../components/HomeHero";
import HomeAbout from "../components/HomeAbout";
import HomeContact from "../components/HomeContact";
import HomeProjects from "../components/HomeProjects";

export const Section3 = () => (<HomeContact></HomeContact>);
export const Section4 = ({projects, title}) => (<HomeProjects projects={projects} title={title}></HomeProjects>);

const IndexPage = ({ data }) => {
    const frontmatter = data.page.frontmatter;
    const projects = data.projects.edges.map((p) => p.node.frontmatter);

    const getAboutItems = () => {
        return [
            [
                {id:1, title:'Backend', body: "Comfortable with most modern frameworks, various API patterns/protocols & working at scale"},
                {id:2, title:'Frontend', body: "Comfortable with most modern frameworks, jQuery & vanilla JS"},
                {id:3, title:'Code quality', body: "I enjoy writing well tested SOLID code and strive to produce code that's understandable for the next guy"},
                {id:4, title:'Principles', body: "Above all else I believe in measuring twice and cutting once, in avoiding unnecessary complexity and in keeping code DRY"},
                {id:5, title:'Process', body: "I'm an advocate of Agile (when not implemented dogmatically) but I'm happy to work using other/less formal practices too"},
            ],
            [
                {id:1, title:'Fullstack skills', body: "Put simply, I have all of the skills required to make your web app. Need an API? Need a UI? Server support? No problem"},
                {id:2, title:'From concept to product', body: "I'm capable of producing a spec, either collaboratively or alone, and taking it all the way through to production"},
                {id:3, title:'Robust software', body: "Using a test driven approach, I create robust, reliable software that you'll be happy to put your name against"},
                {id:4, title:'Fast & efficient', body: "Using the latest technologies & techniques I build fast software that your customers will love to use"},
                {id:5, title:'Maintenance & support', body: "I offer retainers for ongoing maintenance and support, please get in touch for further info"},
            ]
        ];
    }

    return (
        <ScrollerLayout pageName={"home"}
                        title={"Full stack PHP freelancer based in Newcastle"}
                        description={"Full stack PHP freelancer based in Newcastle. Primarily working with Laravel & Vue."}>
            <HomeHero title={frontmatter.title}
                      subheading={frontmatter.subheading}>
            </HomeHero>

            <HomeAbout aboutItems={getAboutItems()}>

            </HomeAbout>

            <Section4 projects={projects} title={"Recent work"}/>

            <Section3 />
        </ScrollerLayout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    page: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    },
    projects: allMarkdownRemark(limit: 3, filter: {fields: {slug: {regex: "^/recent-work/"}}}) {
        edges {
          node {
            frontmatter {
             title
              description
              objective
              tech
              Images {
                image {
                  publicURL
                  ext
                  childImageSharp {
                      fluid(maxWidth: 2048, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
              }
              date
            }
          }
        }
      }
  }
`
