import React from 'react'
import {Link} from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
const getLabel = (text) => (
    <span>{text}</span>
);

const ContactLinks = ({labels}) => (
    <div className={"c-contact-links"}>
        <a href="mailto:mail@john-richardson.net" className="no-border">
            <FontAwesomeIcon icon={faEnvelope}  size="s" />
            {labels ? getLabel('mail@john-richardson.net') : ''}
        </a>
        <a href="tel:07957918063" className="no-border">
            <FontAwesomeIcon icon={faPhone}  size="s"/>
            {labels ? getLabel('079 579 18063') : ''}
        </a>
        <Link to="" className={"c-contact-links--social no-border"}>
            <FontAwesomeIcon icon={faTwitter}  size="s"/>
            {labels ? getLabel('Reach me on Twitter') : ''}
        </Link>
        <Link to="" className={"c-contact-links--social no-border"}>
            <FontAwesomeIcon icon={faLinkedin}  size="s"/>
            {labels ? getLabel('Reach me on LinkedIn') : ''}
        </Link>
    </div>
);

export default ContactLinks;
