import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {faCaretDown, faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TextGrid = ({items, showContact = false}) => {
    const contactItem = () => {
        if (showContact) return (
            <article className={"c-text-grid-contact l-flex l-flex--column l-flex--center-middle"} key={"c-text-grid-contact"}>

                <p className={"copy copy-large"}>Let's talk about your project</p>
                <div className={"c-text-grid-contact-links"}>
                    <Link to=""
                          className="no-border">
                        <FontAwesomeIcon icon={faTwitter}  size="2x" />
                    </Link>
                    <a href="tel:07957918063"
                       className="no-border">
                        <FontAwesomeIcon icon={faPhone}  size="2x" />
                    </a>
                    <Link to="" className="no-border">
                        <FontAwesomeIcon icon={faLinkedin}  size="2x" />
                    </Link>
                    <a className="no-border"
                       href="mailto:mail@john-richardson.net">
                        <FontAwesomeIcon icon={faEnvelope}  size="2x" />
                    </a>
                </div>
            </article>
        );
    }

    const textItems = [];

    const TextItem = (k, item) => {
        const [open, setOpen] = useState(0);

        textItems.push({
            id: item.id,
            open: open,
            setOpen: setOpen
        });

        const handleClick = (id) => {
            setOpen(!open);

            textItems.forEach((i) => {
                if(i.id === id) {
                    i.setOpen(!i.open)
                } else {
                    i.setOpen(false);
                }
            })
        }

        return (
            <article key={item.id}
                     onClick={() => handleClick(item.id)}
                     className={open ? 'open' : ''}>
                <header className={"l-flex l-flex--middle"}>
                    <span>{k+1}</span>
                    <p className={"copy copy-large copy-middle-fix"}>{item.title} <FontAwesomeIcon icon={faCaretDown}  size="s" /></p>
                </header>

                <p className={"copy copy-secondary"}>{item.body}</p>
            </article>
        );
    }

    return (
        <div className={"c-text-grid l-component l-flex l-flex--wrap"}>
            {items.map((item, k) => {
                let eles = [TextItem(k, item)];

                if(k === items.length - 1) {
                    eles.push(contactItem());
                }

                return eles;
            })}
        </div>
    )
}

TextGrid.propTypes = {
    items: PropTypes.array
}

export default TextGrid
